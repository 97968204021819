// Fonts
// @import url("https://fonts.googleapis.com/css?family=Nunito");
// @import url("https://rsms.me/inter/inter.css");

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

body {
  height: 100vh;
}

p {
  font-family: $font-family-sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

a,
a:hover,
a:focus {
  transition: all 0.3s;
}

.btn-light {
  background-color: rgb(238, 241, 244);
}

header {
  padding-bottom: 0.35rem;
}

// .content {
//   padding: 1.5rem 4.5rem;
// }

// .navbar {
//   padding-top: 0;
//   padding-bottom: 0;
// }

// .navbar-nav {
//   .nav-link {
//     text-transform: uppercase;
//     font-size: 12px;
//   }
//   .active {
//     & > .nav-link {
//       border-bottom: 3px solid #ffffff;
//     }
//   }
// }

.navbar-expand {
  .navbar-nav {
    .nav-item {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }
    .nav-link {
      padding-top: 1.25rem;
      padding-bottom: 1rem;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }
  }
}

.menu-card {
  margin-top: 2rem;
  div {
    vertical-align: middle;
    a {
      background-color: theme-color-level(info, -9);
      display: block;
      padding-top: 5rem;
      padding-bottom: 5rem;
      font-size: 1.4rem;
    }
  }
}

.table {
  thead {
    background-color: theme-color-level(primary, -11);
    th {
      text-transform: uppercase;
      border-bottom: 3px solid theme-color-level(info, -9);
    }
  }
  th {
    border-top: 1px solid theme-color-level(secondary, -11);
  }
  td {
    border-top: 1px solid theme-color-level(secondary, -11);
  }
  tr {
    &.no-hover {
      &:hover {
        background-color: #ffffff;
      }
    }
  }
}

label {
  font-weight: 500 !important;
}

img {
  &.no-data {
    max-width: 150px;
  }
}

.card-login {
  background-color: theme-color-level(primary, -10);
}

.title-login {
  font-weight: 700 !important;
}

.btn-login {
  height: 45px;
  margin-top: 2rem;
}

// Modal
h3.modal-title {
  margin: 0;
  padding: 0;
}
.modal-header,
.modal-footer {
  border: none;
}
.modal-header {
  align-items: center;
}
.modal-content {
  padding: 16px;
}
